import React, { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";

import { downloadManifest } from "../../inventories/reservation.slice";
import { CfcIconButton } from "../../../app/components/CfcIconButton";

const ManifestPrintBtn = ({
  manifestId,
  isBig,
}: {
  manifestId: number;
  isBig?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const downloadPdf =
    (event: React.MouseEvent<HTMLElement>) => async (id: number) => {
      event.stopPropagation();
      setIsLoading(true);
      const res = await downloadManifest(id);
      setIsLoading(false);
      if (res.error) {
        setIsError(true);
      }
    };

  if (isLoading) {
    return <CircularProgress size={24} />;
  } else if (isError) {
    return (
      <CfcIconButton aria-label="error" color="secondary">
        <ErrorIcon />
      </CfcIconButton>
    );
  } else {
    if (isBig) {
      return (
        <Button
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          onClick={(event) => downloadPdf(event)(manifestId)}
        >
          Download PDF
        </Button>
      );
    }
    return (
      <CfcIconButton
        aria-label="pdf"
        color="primary"
        onClick={(event) => downloadPdf(event)(manifestId)}
      >
        <PictureAsPdfIcon />
      </CfcIconButton>
    );
  }
};

export default ManifestPrintBtn;
