import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import IReservation from "../../app/interfaces/IReservation";
import IError from "../../app/interfaces/IError";
import ICouple from "../../app/interfaces/ICouple";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const reservations: IReservation[] = [
  {
    id: 1,
    reservedDate: new Date("2022-01-10"),
    receiptant: {
      coupleid: 1,
      couplecode: "D1111111",
      patient: 1,
      partner: 2,
      isDonor: true,
      isSurrogate: false,
      patientInstance: {
        firstname: "John",
        surname: "Doe",
        personid: 1,
        personsex: 2,
      },
      partnerInstance: {
        firstname: "Jane",
        surname: "Doe",
        personid: 2,
        personsex: 1,
      },
    },
    donor: {
      coupleid: 1,
      couplecode: "D1111111",
      patient: 1,
      partner: 2,
      isDonor: true,
      isSurrogate: false,
      patientInstance: {
        firstname: "John",
        surname: "Doe",
        personid: 1,
        personsex: 2,
      },
      partnerInstance: {
        firstname: "Jane",
        surname: "Doe",
        personid: 2,
        personsex: 1,
      },
    },
    partnerIVF: false,
    reservationType: "IVF",
    reservedAmount: 100,
    clinic: { id: 1, name: "Clinic 1" },
    expiryDate: new Date("2020-01-01"),
    reservedBy: "John Doe",
  },
];

interface IDonorReservationRequest extends GenericState<IReservation[]> {
  selected: ICouple | null;
  search: string;
}

const initialState: IDonorReservationRequest = {
  loading: false,
  data: null,
  error: null,
  selected: null,
  search: "",
};

export const fetchDonorReservations = createAsyncThunk<
  IReservation[],
  number,
  {
    rejectValue: IError;
  }
>("donor/fetchReservations", async (donorId: number, { rejectWithValue }) => {
  try {
    const response = reservations.filter(
      (reservation) => reservation.donor.coupleid === donorId
    );

    if (response.length === 0) {
      return rejectWithValue({
        code: 400,
        message: "No reservation found",
      });
    } else {
      return response.sort();
    }
  } catch (error) {
    return rejectWithValue({
      code: 500,
      message: "Internal server error",
    });
  }
});

export const donorReservationSlice = createSlice({
  name: "donor",
  initialState,
  reducers: {
    setSelectedDonor: (state, action) => {
      state.selected = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    genericAsyncThunkReducer<IReservation[], number>(
      builder,
      fetchDonorReservations
    );
  },
});

export const { setSelectedDonor, setSearch } = donorReservationSlice.actions;
export default donorReservationSlice.reducer;
