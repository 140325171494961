// @flow
import React from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import Menu from "./Menu";
import AppWrapper from "./AppWrapper";
import { mainTheme } from "./myThemes";

function Template({ isMain }: { isMain: boolean }) {
  return (
    <MuiThemeProvider theme={mainTheme}>
      {isMain ? (
        <Menu>
          <Outlet />
        </Menu>
      ) : (
        <AppWrapper>
          <Outlet />
        </AppWrapper>
      )}
    </MuiThemeProvider>
  );
}

export default Template;
