import React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";

interface BootstrapDialogActionsProps {
  text?: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
}

const BootstrapDialogActions = ({
  leftButton,
  rightButton,
}: {
  leftButton?: BootstrapDialogActionsProps;
  rightButton?: BootstrapDialogActionsProps;
}) => {
  return (
    <DialogActions
      sx={{
        justifyContent: "space-between",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      {leftButton ? (
        <LoadingButton
          autoFocus
          loading={leftButton.isLoading}
          loadingIndicator="Loading..."
          variant="outlined"
          onClick={leftButton.onClick}
        >
          {leftButton.text || "Cancel"}
        </LoadingButton>
      ) : (
        <span />
      )}
      {rightButton && (
        <LoadingButton
          loading={rightButton.isLoading}
          loadingIndicator="Loading..."
          variant="outlined"
          onClick={rightButton.onClick}
        >
          {rightButton.text || "Submit"}
        </LoadingButton>
      )}
    </DialogActions>
  );
};

export default BootstrapDialogActions;
