import React, { useState } from "react";

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const toggle = (status?: boolean) => {
    setOpen(status !== undefined ? status : !open);
  };

  return {
    open,
    toggle,
  };
};
