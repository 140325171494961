import React, { useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import Chip from "@mui/material/Chip";
import PreviewIcon from "@mui/icons-material/Preview";

import { useAppSelector } from "../../app/hooks";
import IManifest from "../../app/interfaces/IManifest";
import { statusDict } from "./manifestStatusDict";
import { useDialog } from "../../app/hooks/useDialog";
import BootstrapDialog from "../../app/components/BootstrapDialog";
import ManifestPrintBtn from "./components/ManifestPrintBtn";
import ManifestDetail from "./components/ManifestDetail";
import ClinicLabel from "../locations/components/ClinicLabel";
import { CfcIconButton } from "../../app/components/CfcIconButton";
import { getFormattedDateAndTime } from "./functions/getFormattedDateAndTime";

const ManifestTable = ({ rows }: { rows: IManifest[] | null }) => {
  const { open, toggle } = useDialog();

  const [selectedManifest, setSelectedManifest] = useState<IManifest | null>(
    null
  );

  const { data: locations } = useAppSelector((state) => state.location);
  const { data: manifestStatus } = useAppSelector(
    (state) => state.manifestStatus
  );

  const viewStocks =
    (event: React.MouseEvent<HTMLElement>) => (item: IManifest) => {
      setSelectedManifest(item);
      toggle(true);
    };

  let columns: GridColDef[] = [
    {
      field: "manifestId",
      headerName: "Manifest ID",
      type: "string",
      flex: 1,
    },
    {
      field: "sendingClinicId",
      headerName: "Sending Clinic",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return locations?.find(
          (item) => item.locationId === params.row.sendingClinicId
        )?.chartingLabel;
      },
      renderCell: (params: GridValueGetterParams) => {
        return <ClinicLabel locationId={params.row.sendingClinicId} />;
      },
    },
    {
      field: "receivingClinicId",
      headerName: "Receiving Clinic",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return locations?.find(
          (item) => item.locationId === params.row.receivingClinicId
        )?.chartingLabel;
      },
      renderCell: (params: GridValueGetterParams) => {
        return <ClinicLabel locationId={params.row.receivingClinicId} />;
      },
    },
    { field: "comments", headerName: "Comments", type: "string", flex: 1 },
    {
      field: "manifestCreateDate",
      headerName: "Create Date",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return getFormattedDateAndTime(params.row.manifestCreateDate);
      },
    },
    {
      field: "manifestStatusId",
      headerName: "Manifest Status",
      type: "strintg",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return manifestStatus?.find(
          (status) => status.manifestStatusId === params.row.manifestStatusId
        )?.statusDesc;
      },
      renderCell: (params: GridValueGetterParams) => {
        if (!!params.row.manifestStatusId) {
          return (
            <Chip
              label={
                manifestStatus?.find(
                  (item) =>
                    item.manifestStatusId === params.row.manifestStatusId
                )?.statusDesc
              }
              color={statusDict[params.row.manifestStatusId].color}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "manifestCheckOutDateTime",
      headerName: "Check Out Date",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.manifestCheckOutDateTime
          ? getFormattedDateAndTime(params.row.manifestCheckOutDateTime)
          : "";
      },
    },
    {
      field: "manifestCheckInDateTime",
      headerName: "Check In Date",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.manifestCheckInDateTime
          ? getFormattedDateAndTime(params.row.manifestCheckInDateTime)
          : "";
      },
    },
    {
      field: "details",
      headerName: "Details",
      type: "string",
      flex: 1,
      sortable: false,
      description: "View Manifest Details",
      maxWidth: 80,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <CfcIconButton
            aria-label="view"
            color="primary"
            onClick={(event) => viewStocks(event)(params.row)}
          >
            <PreviewIcon />
          </CfcIconButton>
        );
      },
    },
    {
      field: "pdf",
      headerName: "PDF",
      type: "string",
      flex: 1,
      sortable: false,
      description: "Download Manifest PDF",
      maxWidth: 80,
      renderCell: (params) => {
        return <ManifestPrintBtn manifestId={params.row.manifestId} />;
      },
    },
  ];
  return (
    <>
      {rows && (
        <DataGrid
          getRowId={(row) => row.manifestId}
          rows={rows}
          columns={columns}
          autoHeight
          initialState={{
            sorting: {
              sortModel: [{ field: "manifestStatusId", sort: "desc" }],
            },
          }}
        />
      )}
      {selectedManifest && (
        <BootstrapDialog
          open={open}
          toggle={toggle}
          id="view-manifest-dialog"
          title="View Manifest Details"
          content={<ManifestDetail manifest={selectedManifest} />}
          fullWidth
          maxWidth="md"
        />
      )}
    </>
  );
};

export default ManifestTable;
