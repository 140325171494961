import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapStyleDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
}

const BootstrapStyleDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <BootstrapStyleDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </BootstrapStyleDialogTitle>
  );
};

interface DialogContentProps extends DialogProps {
  toggle: (value?: boolean) => void;
  content: React.ReactNode;
  title: string;
  id?: string;
  keepMounted?: boolean;
}

const BootstrapDialog = (props: DialogContentProps) => {
  const { open, toggle, content, title, id, keepMounted, ...other } = props;

  const hanldeClose = (
    event: React.MouseEvent<HTMLElement>,
    reason: string
  ) => {
    if (reason !== "backdropClick" || !keepMounted) {
      toggle(false);
    }
  };

  return (
    <BootstrapStyleDialog
      onClose={hanldeClose}
      aria-labelledby={id || "bootstrap-dialog"}
      open={open}
      disableEscapeKeyDown={!!keepMounted}
      hideBackdrop={!keepMounted}
      {...other}
    >
      <BootstrapDialogTitle
        id={id || "bootstrap-dialog"}
        onClose={(event) => hanldeClose(event, "topCancelClick")}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
    </BootstrapStyleDialog>
  );
};

export default BootstrapDialog;
