import React, { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { CfcColorLabel } from "../../../app/components/CfcColorLabel";

const ClinicLabel = ({ locationId }: { locationId: number | null }) => {
  const { data: locations } = useAppSelector((state) => state.location);

  const clinic = useMemo(() => {
    return locations?.find((i) => i.locationId === locationId);
  }, [locations, locationId]);

  if (!clinic) {
    return <span>N/A</span>;
  } else {
    return (
      <CfcColorLabel color={clinic.chartingcolor} text={clinic.chartingLabel} />
    );
  }
};

export default ClinicLabel;
