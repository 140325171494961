import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import HandleDataWrap from "../../app/containers/HandleDataWrap";
import { fetchManifestList } from "./manifest.slice";
import { fetchLocations } from "../inventories/location.slice";
import { fetchManifestStatus } from "./manifest.status.slice";
import { fetchUsers } from "../users/user.slice";
import ManifestTable from "./ManifestTable";

const ManifestList = () => {
  const dispatch = useAppDispatch();
  const { data, loading, error } = useAppSelector(
    (state) => state.manifestList
  );
  const { data: locations } = useAppSelector((state) => state.location);
  const { data: manifestStatus } = useAppSelector(
    (state) => state.manifestStatus
  );
  const { data: users } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!locations) {
      dispatch(fetchLocations());
    }
  }, [dispatch, locations]);

  useEffect(() => {
    if (!manifestStatus) {
      dispatch(fetchManifestStatus());
    }
  }, [dispatch, manifestStatus]);

  useEffect(() => {
    if (!users) {
      dispatch(fetchUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    dispatch(fetchManifestList());
  }, [dispatch]);

  return (
    <HandleDataWrap loading={loading} error={error}>
      <ManifestTable rows={data} />
    </HandleDataWrap>
  );
};

export default ManifestList;
