import React, { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { CfcColorLabel } from "../../../app/components/CfcColorLabel";

const UserLabel = ({ userId }: { userId: number | null }) => {
  const { data: users } = useAppSelector((state) => state.user);

  const user = useMemo(() => {
    return users?.find((i) => i.userid === userId);
  }, [users, userId]);

  if (!user) {
    return <span>N/A</span>;
  } else {
    return <CfcColorLabel color={user.color} text={user.username} />;
  }
};

export default UserLabel;
