import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IError from "../../app/interfaces/IError";
import IManifestStatus from "../../app/interfaces/IManifestStatus";
import CfcApi from "../../services/CfcApi";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const initialState: GenericState<IManifestStatus[]> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchManifestStatus = createAsyncThunk<
  IManifestStatus[],
  void,
  { rejectValue: IError }
>("manifestStatus/get", async (val: void, { rejectWithValue }) => {
  try {
    const res = await CfcApi.get<IManifestStatus[]>(
      "/manifests/GetAllManifestStatuses"
    );
    const { data: statuses } = res;
    if (statuses && statuses.length > 0) {
      return statuses;
    } else {
      return rejectWithValue({
        code: 404,
        message: "Manifests not found",
      });
    }
  } catch (error) {
    return rejectWithValue({
      code: error.response.status,
      message: error.message,
    });
  }
});

export const manifestStatusSlice = createSlice({
  name: "manifestStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    genericAsyncThunkReducer<IManifestStatus[], void>(
      builder,
      fetchManifestStatus
    );
  },
});

export default manifestStatusSlice.reducer;
