import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IError from "../../app/interfaces/IError";
import IDonorInventory from "../../app/interfaces/IDonorInventory";
import IDonorInventoryDict from "../../app/interfaces/IDonorInventoryDict";
import CfcApi from "../../services/CfcApi";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const initialState: GenericState<IDonorInventoryDict> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchInventoryList = createAsyncThunk<
  IDonorInventory[],
  number,
  { rejectValue: IError }
>("manifestInventories/get", async (val: number, { rejectWithValue }) => {
  try {
    const res = await CfcApi.get<IDonorInventory[]>(
      `/cdt/GetLogisticsItemsByReservationId?reservationId=${val}`
    );
    const { data } = res;
    if (data && data.length > 0) {
      return data;
    } else {
      return rejectWithValue({
        code: 404,
        message: "Inventories not found",
      });
    }
  } catch (error) {
    return rejectWithValue({
      code: error.response.status,
      message: error.message,
    });
  }
});

export const manifestInventoriesSlice = createSlice({
  name: "manifestInventories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryList.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchInventoryList.fulfilled, (state, action) => {
      state.loading = false;

      let data = state.data ? { ...state.data } : {};
      data[action.meta.arg] = action.payload;
      state.data = data;
      state.error = null;
    });
    builder.addCase(fetchInventoryList.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = {
          code: 500,
          message: "An unknown error occurred",
        };
      }
    });
  },
});

export default manifestInventoriesSlice.reducer;
