import React from "react";
import {
  Link as RouterLink,
  useResolvedPath,
  useMatch,
} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { SvgIconProps } from "@mui/material/SvgIcon";

const ListItemLinkStyled = styled(ListItemButton)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "& .MuiListItemText-root, & .MuiListItemIcon-root": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

export interface ListItemLinkProps {
  icon?: SvgIconProps;
  text: string;
  url: string;
  onClick?: () => void;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, text, url } = props;

  let resolved = useResolvedPath(url);
  let match = useMatch({ path: resolved.pathname, end: true });

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement>((itemProps, ref) => (
        <RouterLink to={url} ref={ref} {...itemProps} />
      )),
    [url]
  );

  return props.onClick ? (
    <ListItemLinkStyled onClick={props.onClick} selected={!!match}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={text} />
    </ListItemLinkStyled>
  ) : (
    <ListItemLinkStyled component={renderLink} selected={!!match}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={text} />
    </ListItemLinkStyled>
  );
}

export default ListItemLink;
