import React from "react";
import LogoSvg from "../../images/cfc-logo-2015.svg";

const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="App-Form-Wrapper">
      <div className="App-Form">
        <img src={LogoSvg} className="App-Logo" alt="CFC Logo" />
        {children}
      </div>
    </div>
  );
};

export default AppWrapper;
