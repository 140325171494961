import {
  createAsyncThunk,
  createSlice,
  combineReducers,
  PayloadAction,
} from "@reduxjs/toolkit";
import IError from "../../app/interfaces/IError";
import ICdtReservation, {
  IManifestCreateInputs,
} from "../../app/interfaces/ICdtReservation";
import CfcApi from "../../services/CfcApi";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";
import { handleDownloadFile } from "../../app/functions/handleDownloadFile";
import IUpdateManifestId from "../../app/interfaces/IUpdateManifestId";

const initialState: GenericState<ICdtReservation[]> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchManifests = createAsyncThunk<
  ICdtReservation[],
  void,
  { rejectValue: IError }
>("manifests/get", async (val: void, { rejectWithValue }) => {
  try {
    const res = await CfcApi.get<ICdtReservation[]>("/cdt/GetAllReservations");
    const { data: manifests } = res;
    if (manifests && manifests.length > 0) {
      return manifests;
    } else {
      return rejectWithValue({
        code: 404,
        message: "Manifests not found",
      });
    }
  } catch (error) {
    return rejectWithValue({
      code: error.response.status,
      message: error.message,
    });
  }
});

export const manifestSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    updateManifestId: (state, action: PayloadAction<IUpdateManifestId>) => {
      state.data?.map((manifest) => {
        if (action.payload.reservationIds.includes(manifest.reservationId)) {
          // eslint-disable-next-line no-param-reassign
          manifest.manifestId = action.payload.manifestId;
          // eslint-disable-next-line no-param-reassign
          manifest.manifestStatusId = action.payload.manifestStatusId;
        }
        return manifest;
      });
    },
  },
  extraReducers: (builder) => {
    genericAsyncThunkReducer<ICdtReservation[], void>(builder, fetchManifests);
  },
});

export const downloadManifest = async (manifestId: number) => {
  try {
    const res = await CfcApi.get(
      `/manifests/CreatePDFByManifestId?manifestId=${manifestId}`,
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
        },
      }
    );
    handleDownloadFile(res.data, `manifest-${manifestId}.pdf`);
    return res.data;
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
};

export const createManifest = createAsyncThunk<
  ICdtReservation,
  IManifestCreateInputs,
  { rejectValue: IError }
>(
  "manifests/create",
  async (inputs: IManifestCreateInputs, { rejectWithValue }) => {
    try {
      const res = await CfcApi.post<ICdtReservation>(
        "/Manifests/CreateManifest",
        inputs
      );
      const { data: manifest } = res;
      if (manifest) {
        return manifest;
      } else {
        return rejectWithValue({
          code: 404,
          message: "Manifest create failed",
        });
      }
    } catch (error) {
      return rejectWithValue({
        code: error.response.status,
        message: error.message,
      });
    }
  }
);

const initialState2: GenericState<ICdtReservation> = {
  loading: false,
  data: null,
  error: null,
};

export const createManifestSlice = createSlice({
  name: "createManifest",
  initialState: initialState2,
  reducers: {
    initialSet: (state) => {
      state.loading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    genericAsyncThunkReducer<ICdtReservation, IManifestCreateInputs>(
      builder,
      createManifest
    );
  },
});

export const { updateManifestId } = manifestSlice.actions;
export const { initialSet } = createManifestSlice.actions;
export default combineReducers({
  manifestList: manifestSlice.reducer,
  createManifest: createManifestSlice.reducer,
});
