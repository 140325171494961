import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import IError from "../../app/interfaces/IError";
import IDonorSperm from "../../app/interfaces/IDonorSperm";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const donors: IDonorSperm[] = [
  {
    reservedId: 1,
    freezeDate: new Date("2022-01-10"),
    clinic: "Clinic 1",
    type: "Straw",
    reference: 14,
    tank: "A",
    position: "A",
    goblet: "A",
    FinalCount: 100,
    motile: 100,
    reservedBy: "John Doe",
  },
  {
    reservedId: 2,
    freezeDate: new Date("2022-01-10"),
    clinic: "Clinic 2",
    type: "Straw",
    reference: 15,
    tank: "A",
    position: "A",
    goblet: "A",
    FinalCount: 100,
    motile: 100,
    reservedBy: "John Doe",
  },
  {
    reservedId: 3,
    freezeDate: new Date("2022-01-10"),
    clinic: "Clinic 1",
    type: "Straw",
    reference: 16,
    tank: "A",
    position: "A",
    goblet: "A",
    FinalCount: 100,
    motile: 100,
    reservedBy: "John Doe",
  },
  {
    reservedId: 4,
    freezeDate: new Date("2022-01-10"),
    clinic: "Clinic 1",
    type: "Straw",
    reference: 17,
    tank: "A",
    position: "A",
    goblet: "A",
    FinalCount: 100,
    motile: 100,
    reservedBy: "John Doe",
  },
  {
    reservedId: 5,
    freezeDate: new Date("2022-01-10"),
    clinic: "Clinic 2",
    type: "Straw",
    reference: 18,
    tank: "A",
    position: "A",
    goblet: "A",
    FinalCount: 100,
    motile: 100,
    reservedBy: "John Doe",
  },
  {
    reservedId: 6,
    freezeDate: new Date("2022-01-10"),
    clinic: "Clinic 1",
    type: "Straw",
    reference: 19,
    tank: "A",
    position: "A",
    goblet: "A",
    FinalCount: 100,
    motile: 100,
    reservedBy: "John Doe",
  },
];

const initialState: GenericState<IDonorSperm[]> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchDonorSperm = createAsyncThunk<
  IDonorSperm[],
  number,
  {
    rejectValue: IError;
  }
>("donor/fetchSperm", async (donorId: number, { rejectWithValue }) => {
  try {
    const response = donors;

    if (response.length === 0) {
      return rejectWithValue({
        code: 400,
        message: "No sperm found",
      });
    } else {
      return response.sort();
    }
  } catch (error) {
    return rejectWithValue({
      code: 500,
      message: "Internal server error",
    });
  }
});

export const donorSpermSlice = createSlice({
  name: "donorSperm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    genericAsyncThunkReducer<IDonorSperm[], number>(builder, fetchDonorSperm);
  },
});

export default donorSpermSlice.reducer;
