import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IError from "../../app/interfaces/IError";
import ILocation from "../../app/interfaces/ILocation";
import CfcApi from "../../services/CfcApi";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const initialState: GenericState<ILocation[]> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchLocations = createAsyncThunk<
  ILocation[],
  void,
  { rejectValue: IError }
>("locations/get", async (val: void, { rejectWithValue }) => {
  try {
    const res = await CfcApi.get<ILocation[]>("/cdt/getalllocations");
    const { data: locationList } = res;
    if (locationList && locationList.length > 0) {
      return locationList;
    } else {
      return rejectWithValue({
        code: 404,
        message: "Locations not found",
      });
    }
  } catch (error) {
    return rejectWithValue({
      code: error.response.status,
      message: error.message,
    });
  }
});

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    genericAsyncThunkReducer<ILocation[], void>(builder, fetchLocations);
  },
});

export default locationSlice.reducer;
