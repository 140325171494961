export const statusDict: {
  [key: number]: {
    color:
      | "default"
      | "primary"
      | "secondary"
      | "error"
      | "warning"
      | "info"
      | "success"
      | undefined;
  };
} = {
  10: { color: "default" },
  15: { color: "primary" },
  20: { color: "secondary" },
  25: { color: "error" },
  30: { color: "warning" },
  35: { color: "info" },
  40: { color: "success" },
};
