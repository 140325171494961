import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export interface CompleteMessageProps {
  title: string;
  content: string;
  onClick?: () => void;
  extraButton?: {
    label: string;
    onClick: () => void;
  };
}

const CompleteMessage = (props: CompleteMessageProps) => {
  return (
    <Box
      sx={{
        //background: (theme) => theme.palette.primary.light,
        borderRadius: "10px",
        color: (theme) => theme.palette.primary.main,
        padding: "24px 20px",
        lineHeight: "30px",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <span
          style={{
            fontSize: "1.875rem",
            fontWeight: "800",
            lineHeight: "60px",
          }}
        >
          {props.title}
        </span>
        <br />
        <svg
          viewBox="0 0 130.2 130.2"
          style={{ width: "100px", display: "block", margin: "0 auto" }}
        >
          <circle className="circle-animation" cx="65.1" cy="65.1" r="60.1" />
          <polyline
            className="check-animation"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
        <div style={{ whiteSpace: "pre-wrap" }}>
          <span
            style={{
              display: "block",
              marginBlockStart: "1em",
              marginBlockEnd: "1em",
              marginInlineStart: "0px",
              marginInlineEnd: "0px",
            }}
          >
            {props.content}
          </span>
        </div>
        {props.onClick && (
          <Box>
            <Button
              color="inherit"
              variant="contained"
              type="button"
              style={{ marginTop: "1rem" }}
              onClick={props.onClick}
            >
              Done
            </Button>
            {props.extraButton && (
              <Button
                color="primary"
                variant="contained"
                type="button"
                style={{ marginTop: "1rem", marginLeft: "1rem" }}
                onClick={props.extraButton.onClick}
              >
                {props.extraButton.label}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CompleteMessage;
