import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import IError from "../../app/interfaces/IError";
import ICouple from "../../app/interfaces/ICouple";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const couples: ICouple[] = [
  {
    coupleid: 1,
    couplecode: "D1111111",
    patient: 1,
    partner: 2,
    isDonor: true,
    isSurrogate: false,
    patientInstance: {
      firstname: "John",
      surname: "Doe",
      personid: 1,
      personsex: 2,
    },
    partnerInstance: {
      firstname: "Jane",
      surname: "Doe",
      personid: 2,
      personsex: 1,
    },
  },
  {
    coupleid: 2,
    couplecode: "D2222222",
    patient: 3,
    partner: 4,
    isDonor: true,
    isSurrogate: false,
    patientInstance: {
      firstname: "Smith",
      surname: "Jack",
      personid: 3,
      personsex: 2,
    },
    partnerInstance: {
      firstname: "Amy",
      surname: "Jackson",
      personid: 4,
      personsex: 1,
    },
  },
  {
    coupleid: 3,
    couplecode: "D3333333",
    patient: 5,
    partner: 6,
    isDonor: true,
    isSurrogate: false,
    patientInstance: {
      firstname: "Rachel",
      surname: "Terry",
      personid: 5,
      personsex: 2,
    },
    partnerInstance: {
      firstname: "Richard",
      surname: "Davis",
      personid: 6,
      personsex: 1,
    },
  },
  {
    coupleid: 4,
    couplecode: "C4444444",
    patient: 7,
    partner: 8,
    isDonor: false,
    isSurrogate: false,
    patientInstance: {
      firstname: "Amma",
      surname: "Michell",
      personid: 7,
      personsex: 2,
    },
    partnerInstance: {
      firstname: "Andy",
      surname: "Michael",
      personid: 8,
      personsex: 1,
    },
  },
  {
    coupleid: 5,
    couplecode: "C5555555",
    patient: 9,
    partner: 10,
    isDonor: false,
    isSurrogate: false,
    patientInstance: {
      firstname: "John",
      surname: "Doe",
      personid: 9,
      personsex: 2,
    },
    partnerInstance: {
      firstname: "Jane",
      surname: "Doe",
      personid: 10,
      personsex: 1,
    },
  },
];

const initialState: GenericState<ICouple[]> = {
  loading: false,
  data: null,
  error: null,
};

interface CoupleInput {
  search: string;
  isDonor?: boolean;
  isGloabl?: boolean;
}

export const fetchCouples = createAsyncThunk<
  ICouple[],
  CoupleInput,
  {
    rejectValue: IError;
  }
>(
  "couple/searchCouple",
  async ({ search, isDonor, isGloabl }: CoupleInput, { rejectWithValue }) => {
    try {
      const response = couples
        .filter((couple) => (isDonor ? couple.isDonor : !couple.isDonor))
        .filter(
          (couple) =>
            couple.patientInstance.firstname
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim()) ||
            couple.patientInstance.surname
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim()) ||
            couple.partnerInstance?.firstname
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim()) ||
            couple.partnerInstance?.surname
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim()) ||
            couple.couplecode
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim())
        );

      if (response.length === 0) {
        return rejectWithValue({
          code: 400,
          message: "No couples found",
        });
      } else {
        return response.sort();
      }
    } catch (error) {
      return rejectWithValue({
        code: 500,
        message: "Internal server error",
      });
    }
  }
);

export const coupleSlice = createSlice({
  name: "couple",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    genericAsyncThunkReducer<ICouple[], CoupleInput>(builder, fetchCouples);
  },
});

export default coupleSlice.reducer;
