import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

type CfcTextFieldProps = TextFieldProps & {
  isDirty?: boolean;
};

export const CfcTextField = styled(TextField)<CfcTextFieldProps>(
  ({ isDirty, theme }) => ({
    "& .MuiInputBase-root": {
      ...(isDirty && {
        backgroundColor: "#f8f5cb",
      }),
    },
  })
);
