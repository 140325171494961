import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

import ICdtReservation from "../../../app/interfaces/ICdtReservation";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchInventoryList } from "../reservation.inventories.slice";
import HandleDataWrap from "../../../app/containers/HandleDataWrap";

interface Props {
  reservation: ICdtReservation;
}

const InventoryList = ({ reservation }: Props) => {
  const dispatch = useAppDispatch();

  const { data, loading, error } = useAppSelector(
    (state) => state.manifestInventories
  );

  useEffect(() => {
    dispatch(fetchInventoryList(reservation.reservationId));
  }, [dispatch, reservation.reservationId]);

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        {reservation.donorCode} {reservation.donorSurName.toUpperCase()},{" "}
        {reservation.donorFirstName}
      </Typography>
      <HandleDataWrap loading={loading} error={error}>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>Freeze Date</TableCell>
              <TableCell>Reference</TableCell>
              <TableCell>Tank</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Goblet Color</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data[reservation.reservationId] &&
              data[reservation.reservationId].map((inventory) => (
                <TableRow key={inventory.inventoryId}>
                  <TableCell component="th" scope="row">
                    {moment(inventory.transDate).format("DD/MMM/YYYY")}
                  </TableCell>
                  <TableCell>{inventory.reference}</TableCell>
                  <TableCell>{inventory.tankName}</TableCell>
                  <TableCell>{inventory.position}</TableCell>
                  <TableCell>{inventory.gobletColour}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </HandleDataWrap>
    </Box>
  );
};

export default InventoryList;
