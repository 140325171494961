import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { styled } from "@mui/material/styles";
import IError from "../interfaces/IError";

const CenterAlert = styled(Alert)(({ theme }) => ({
  "&.MuiPaper-root": {
    justifyContent: "center",
    margin: "0 auto",
    width: "100%",
    maxWidth: "100vh",
  },
}));

const ErrorMessage = ({ error }: { error: IError }) => {
  return (
    <CenterAlert severity="error">
      <AlertTitle>Error Code {error.code}</AlertTitle>
      {error.message}
    </CenterAlert>
  );
};

export default ErrorMessage;
