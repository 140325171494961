// @ts-nocheck
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";

import BootstrapDialogActions from "../../app/components/BootstrapDialogActions";
import InventoryTable from "./components/InventoryTable";
import { CfcSelect } from "../../app/components/CfcSelect";
import { CfcTextField } from "../../app/components/CfcTextField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createManifest,
  downloadManifest,
  updateManifestId,
  initialSet,
} from "./reservation.slice";
import CompleteMessage from "../../app/components/CompleteMessage";

type IFormInputs = {
  sendingClinicId: number;
  receivingClinicId: number;
  comments: string;
  listOfReservationIds: number[];
};

const defaultValues = {
  sendingClinicId: 0,
  receivingClinicId: 0,
  comments: "",
  listOfReservationIds: [],
};

const schema = yup
  .object({
    sendingClinicId: yup
      .number()
      .required()
      .notOneOf([0], "Sending Clinic is Required"),
    receivingClinicId: yup
      .number()
      .required()
      .notOneOf([0], "Receiving Clinic is Required"),
    listOfReservationIds: yup
      .array()
      .min(1, "at least one reservation should be selected")
      .required(),
  })
  .required();

const NewManifest = ({
  preSelectedIds,
  preSendingClinic,
  preReceivingClinic,
  toggle,
  completeCallback,
}: {
  preSelectedIds: number[];
  preSendingClinic: number;
  preReceivingClinic: number;
  toggle: (status?: boolean) => void;
  completeCallback: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<1 | 2>(1);
  const {
    handleSubmit,
    register,
    control,
    getFieldState,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      ...defaultValues,
      listOfReservationIds: preSelectedIds,
      sendingClinicId: preSendingClinic,
      receivingClinicId: preReceivingClinic,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    dispatch(createManifest(data));
    //console.log(data);
  };

  const { data: locations } = useAppSelector((state) => state.location);
  const {
    data: createdManifest,
    loading: createManifestLoading,
    error,
  } = useAppSelector((state) => state.reservation.createManifest);

  const { data: manifests } = useAppSelector(
    (state) => state.reservation.manifestList
  );

  const getFilterRows = useCallback(() => {
    return manifests.filter(
      (item) =>
        !item.manifestId &&
        (item.manifestSendingClinicId || item.currentLocationOfSpermId) ===
          getValues().sendingClinicId &&
        (item.manifestReceivingClinicId || item.receivingClinicId) ===
          getValues().receivingClinicId
    );
  }, [manifests, getValues]);

  const getFilteredReservationId = useCallback(
    (id) => {
      return getFilterRows()
        .map((row) => row.reservationId)
        .includes(id);
    },
    [getFilterRows]
  );

  const updateDefaultSelectedIds = useCallback(() => {
    const { sendingClinicId, receivingClinicId, listOfReservationIds } =
      getValues();
    if (sendingClinicId && receivingClinicId) {
      const newSelectedIds = [...preSelectedIds].filter((id) =>
        getFilteredReservationId(id)
      );
      setValue("listOfReservationIds", newSelectedIds);
    }
  }, [getValues, setValue, preSelectedIds, getFilteredReservationId]);

  return (
    <Box component="form" sx={{}}>
      {createdManifest ? (
        <CompleteMessage
          title="Congratulations!"
          content="You have successfully created the manifest!"
          onClick={() => {
            toggle(false);
            dispatch(
              updateManifestId({
                manifestId: createdManifest.manifestId,
                reservationIds: getValues().listOfReservationIds,
                manifestStatusId: createdManifest.manifestStatusId,
              })
            );
            dispatch(initialSet());
            completeCallback();
            //window.location.reload();
          }}
          extraButton={{
            label: "Print Manifest",
            onClick: () => {
              downloadManifest(createdManifest.manifestId);
            },
          }}
        />
      ) : (
        <Grid container spacing={2}>
          {page === 1 ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{ color: "primary.main" }}
                >
                  New Manifest
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="sending-clinic-label">
                    Sending Clinic
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <CfcSelect
                        {...field}
                        labelId="sending-clinic-label"
                        id="sending-clinic"
                        label="Sending Clinic"
                        onChange={(e) => {
                          field.onChange(parseInt(e.target.value as string));
                          updateDefaultSelectedIds();
                        }}
                        error={Boolean(errors?.sendingClinicId)}
                        isDirty={Boolean(
                          getFieldState("sendingClinicId").isDirty
                        )}
                      >
                        {locations
                          ?.filter((i) => i.brandCode === "CFC")
                          .map((clinic) => (
                            <MenuItem
                              key={clinic.locationId}
                              value={clinic.locationId}
                            >
                              {clinic.chartingLabel}
                            </MenuItem>
                          ))}
                      </CfcSelect>
                    )}
                    name="sendingClinicId"
                    control={control}
                  />
                </FormControl>
                <FormHelperText error={Boolean(errors?.sendingClinicId)}>
                  {errors?.sendingClinicId?.message}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="receiving-clinic-label">
                    Receiving Clinic
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <CfcSelect
                        {...field}
                        labelId="receiving-clinic-label"
                        id="receiving-clinic"
                        label="Receiving Clinic"
                        onChange={(e) => {
                          field.onChange(parseInt(e.target.value as string));
                          updateDefaultSelectedIds();
                        }}
                        error={Boolean(errors?.receivingClinicId)}
                        isDirty={Boolean(
                          getFieldState("receivingClinicId").isDirty
                        )}
                      >
                        {locations
                          ?.filter((i) => i.brandCode === "CFC")
                          .map((clinic) => (
                            <MenuItem
                              key={clinic.locationId}
                              value={clinic.locationId}
                            >
                              {clinic.chartingLabel}
                            </MenuItem>
                          ))}
                      </CfcSelect>
                    )}
                    name="receivingClinicId"
                    control={control}
                  />
                  <FormHelperText error={Boolean(errors?.receivingClinicId)}>
                    {errors?.receivingClinicId?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    render={({ field }) => (
                      <CfcTextField
                        {...field}
                        id="comments"
                        label="Comments"
                        multiline
                        minRows={2}
                        onChange={(e) =>
                          field.onChange(e.target.value as string)
                        }
                        error={Boolean(errors?.comments)}
                        isDirty={Boolean(getFieldState("comments").isDirty)}
                      />
                    )}
                    name="comments"
                    control={control}
                  />
                </FormControl>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <InventoryTable
                    {...field}
                    onSelectionUpdate={(ids) => field.onChange(ids)}
                    selectedIds={[...getValues().listOfReservationIds]}
                    defaultSelectedIds={[...preSelectedIds]}
                    isShort
                    rows={getFilterRows()}
                  />
                )}
                name="listOfReservationIds"
                control={control}
              />
              <FormHelperText error={Boolean(errors?.listOfReservationIds)}>
                {/* ignore ts check now, waiting for this issue in react-hook-form https://github.com/react-hook-form/react-hook-form/issues/987 */}
                {errors?.listOfReservationIds?.message}
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            <BootstrapDialogActions
              {...(page === 2
                ? { leftButton: { text: "Back", onClick: () => setPage(1) } }
                : {})}
              rightButton={{
                text: page === 1 ? "Next" : "Submit",
                onClick: page === 2 ? handleSubmit(onSubmit) : () => setPage(2),
                isLoading: page === 2 && createManifestLoading,
              }}
            />
          </Grid>
        </Grid>
      )}
      <DevTool control={control} />
    </Box>
  );
};

export default NewManifest;
