import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import coupleReducer from "../features/couples/couple.slice";
import donorReservationReducer from "../features/reservations/donor.slice";
import donorSpermReducer from "../features/reservations/donor.sperm.slice";
import locationReducer from "../features/inventories/location.slice";
import manifestReducer from "../features/inventories/reservation.slice";
import manifestStatusReducer from "../features/manifests/manifest.status.slice";
import manifestInventoriesReducer from "../features/inventories/reservation.inventories.slice";
import manifestListReducer from "../features/manifests/manifest.slice";
import userReducer from "../features/users/user.slice";
import manifestItem from "../features/manifests/manifest.item.slice";

export const store = configureStore({
  reducer: {
    couple: coupleReducer,
    donor: donorReservationReducer,
    donorSperm: donorSpermReducer,
    location: locationReducer,
    reservation: manifestReducer,
    manifestStatus: manifestStatusReducer,
    manifestInventories: manifestInventoriesReducer,
    manifestList: manifestListReducer,
    user: userReducer,
    manifestItem: manifestItem,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
