import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IError from "../../app/interfaces/IError";
import IUser from "../../app/interfaces/IUser";
import CfcApi from "../../services/CfcApi";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const initialState: GenericState<IUser[]> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchUsers = createAsyncThunk<
  IUser[],
  void,
  { rejectValue: IError }
>("users/get", async (val: void, { rejectWithValue }) => {
  try {
    const res = await CfcApi.get<IUser[]>("/cdt/GetAllUsers");
    const { data: users } = res;
    if (users && users.length > 0) {
      return users;
    } else {
      return rejectWithValue({
        code: 404,
        message: "Users not found",
      });
    }
  } catch (error) {
    return rejectWithValue({
      code: error.response.status,
      message: error.message,
    });
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    genericAsyncThunkReducer<IUser[], void>(builder, fetchUsers);
  },
});

export default userSlice.reducer;
