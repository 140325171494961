import React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

interface CfcCheckboxProps extends CheckboxProps {
  isDirty?: boolean;
}

export const CfcCheckbox = styled(Checkbox)<CfcCheckboxProps>(
  ({ isDirty, theme }) => ({
    "&.MuiCheckbox-root": {
      ...(isDirty && {
        color: "#b0a60e",
      }),
    },
  })
);
