import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InventoryIcon from "@mui/icons-material/Inventory";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import Template from "./Template";
import Login from "../../features/accounts/Login";
import Inventories from "../../features/inventories/Inventories";
import Reservations from "../../features/reservations/Reservations";
import ManifestList from "../../features/manifests/ManifestList";
import { ListItemLinkProps } from "./ListItemLink";

export const navs: ListItemLinkProps[] = [
  {
    url: "/manifests",
    text: "Manifest Summary",
    icon: <LocalShippingIcon />,
  },
  {
    url: "/reservations",
    text: "Reservation Summary",
    icon: <InventoryIcon />,
  },
  /* {
    url: "/create-reservation",
    text: "Create Reservation",
    icon: <NoteAddIcon />,
  }, */
];

const PageRouter = () => {
  return (
    <BrowserRouter basename="">
      <Routes>
        <Route element={<Template isMain={false} />}>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
        </Route>
        <Route element={<Template isMain />}>
          <Route path="manifests" element={<ManifestList />} />
          <Route path="reservations" element={<Inventories />} />

          {/* <Route path="create-reservation" element={<Reservations />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PageRouter;
