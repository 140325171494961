import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import BootstrapDialog from "../../app/components/BootstrapDialog";
import { useDialog } from "../../app/hooks/useDialog";
import NewManifest from "./NewManifest";
import InventoryTable from "./components/InventoryTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchManifests } from "./reservation.slice";
import { fetchManifestStatus } from "../manifests/manifest.status.slice";
import { fetchLocations } from "./location.slice";
import { removeDuplicates } from "../../app/functions/arrayFucntions";
import HandleDataWrap from "../../app/containers/HandleDataWrap";
import { CfcSelect } from "../../app/components/CfcSelect";
import { ManifestStatusEnum } from "../../app/interfaces/ManifestFilterEnum";

const Inventories = () => {
  const { open, toggle } = useDialog();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [sendingClinic, setSendingClinc] = useState<number>(0);
  const [receivingClinic, setReceivingClinic] = useState<number>(0);
  const [filter, setFilter] = useState<ManifestStatusEnum>(
    ManifestStatusEnum.All
  );

  const dispatch = useAppDispatch();
  const {
    data: manifests,
    loading,
    error,
  } = useAppSelector((state) => state.reservation.manifestList);
  const { data: locations } = useAppSelector((state) => state.location);
  const { data: manifestStatus } = useAppSelector(
    (state) => state.manifestStatus
  );

  useEffect(() => {
    if (!locations) {
      dispatch(fetchLocations());
    }
  }, [dispatch, locations]);

  useEffect(() => {
    if (!manifestStatus) {
      dispatch(fetchManifestStatus());
    }
  }, [dispatch, manifestStatus]);

  useEffect(() => {
    dispatch(fetchManifests());
  }, [dispatch]);

  const onSelectionUpdate = (ids: number[]) => {
    setSelectedIds(ids);

    const selectedItems = manifests?.filter((item) =>
      ids.includes(item.reservationId)
    );

    const uniqueSending =
      selectedItems &&
      removeDuplicates<number>(
        selectedItems.map(
          (item) =>
            item.manifestSendingClinicId || item.currentLocationOfSpermId
        )
      ).length === 1
        ? selectedItems[0].manifestSendingClinicId ||
          selectedItems[0].currentLocationOfSpermId
        : 0;
    const uniqueReceiving =
      selectedItems &&
      removeDuplicates<number>(
        selectedItems.map(
          (item) => item.manifestReceivingClinicId || item.receivingClinicId
        )
      ).length === 1
        ? selectedItems[0].manifestReceivingClinicId ||
          selectedItems[0].receivingClinicId
        : 0;
    setSendingClinc(uniqueSending);
    setReceivingClinic(uniqueReceiving);
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => toggle(true)}
        >
          Create Manifest
        </Button>
        <CfcSelect
          value={filter}
          onChange={(event) =>
            setFilter(parseInt(event.target.value as string))
          }
          labelId="manifest-spreadsheet-label"
          id="manifest-spreadsheet"
          sx={{ ml: "2rem", mb: "0.5rem" }}
        >
          <MenuItem key={ManifestStatusEnum.All} value={ManifestStatusEnum.All}>
            Select All
          </MenuItem>
          <MenuItem
            key={ManifestStatusEnum.WithManifest}
            value={ManifestStatusEnum.WithManifest}
          >
            Reservations with Manifest
          </MenuItem>
          <MenuItem
            key={ManifestStatusEnum.WithoutManifest}
            value={ManifestStatusEnum.WithoutManifest}
          >
            Reservations Without Manifest
          </MenuItem>
        </CfcSelect>
      </Box>
      <HandleDataWrap loading={loading} error={error}>
        <InventoryTable
          rows={manifests}
          onSelectionUpdate={onSelectionUpdate}
          selectedIds={selectedIds}
          statusFilter={filter}
        />
      </HandleDataWrap>
      <BootstrapDialog
        open={open}
        toggle={toggle}
        id="create-manifest-dialog"
        title="Create New Manifest"
        keepMounted
        content={
          <NewManifest
            preSelectedIds={selectedIds}
            preSendingClinic={sendingClinic}
            preReceivingClinic={receivingClinic}
            toggle={toggle}
            completeCallback={() => onSelectionUpdate([])}
          />
        }
        fullWidth
        maxWidth="xll"
      />
    </>
  );
};

export default Inventories;
