import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import FiberPinIcon from "@mui/icons-material/FiberPin";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState<number | null>();
  const [isPin, setIsPin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const login = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    console.log("login");
    window.location.href = "/reservations";
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "30ch" },
      }}
      noValidate
      onSubmit={login}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ color: "primary.main" }}
          >
            Login to your account
          </Typography>
        </Grid>
        {isPin ? (
          <Grid item xs={12}>
            <TextField
              required
              id="pin"
              label="Pin"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={pin || ""}
              onChange={(e) => setPin(parseInt(e.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FiberPinIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <TextField
                required
                id="username"
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button variant="text" onClick={() => setIsPin(!isPin)}>
            Switch to {isPin ? "username/password" : "pin"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
