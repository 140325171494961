import { ActionReducerMapBuilder, AsyncThunk, Draft } from "@reduxjs/toolkit";
import IError from "../interfaces/IError";

export interface GenericState<T> {
  loading: boolean;
  data: T | null;
  error: IError | null;
}

export const genericAsyncThunkReducer = <T, T2>(
  builder: ActionReducerMapBuilder<GenericState<T>>,
  thunk: AsyncThunk<Draft<T>, T2, { rejectValue: IError }>
) => {
  builder.addCase(thunk.pending, (state) => {
    state.loading = true;
    state.data = null;
    state.error = null;
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.error = null;
  });
  builder.addCase(thunk.rejected, (state, action) => {
    state.loading = false;
    state.data = null;
    if (action.payload) {
      state.error = action.payload;
    } else {
      state.error = {
        code: 500,
        message: "An unknown error occurred",
      };
    }
  });
};
