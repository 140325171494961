import React from "react";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";

interface CfcSelectProps extends SelectProps {
  isDirty?: boolean;
}

export const CfcSelect = styled(Select)<CfcSelectProps>(
  ({ isDirty, theme }) => ({
    "&.MuiOutlinedInput-root": {
      ...(isDirty && {
        backgroundColor: "#f8f5cb",
      }),
    },
  })
);
