import React, { ReactElement, FC } from "react";
import LoadingStatus from "../components/LoadingStatus";
import ErrorMessage from "../components/ErrorMessage";
import IError from "../interfaces/IError";

interface Props {
  loading: boolean;
  error: IError | null;
  children: ReactElement;
}

const HandleDataWrap: FC<Props> = ({ loading, error, children }) => {
  if (loading) return <LoadingStatus />;
  else if (error) return <ErrorMessage error={error} />;
  else return children;
};

export default HandleDataWrap;
