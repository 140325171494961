import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridSelectionModel,
  GridFilterItem,
  GridFilterModel,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Button from "@mui/material/Button";
import InventoryIcon from "@mui/icons-material/Inventory";
import moment from "moment";
import ICdtReservation from "../../../app/interfaces/ICdtReservation";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import InventoryList from "./InventoryList";
import { useDialog } from "../../../app/hooks/useDialog";
import BootstrapDialog from "../../../app/components/BootstrapDialog";
import { CfcCheckbox } from "../../../app/components/CfcCheckbox";
import { ManifestStatusEnum } from "../../../app/interfaces/ManifestFilterEnum";
import { statusDict } from "../../manifests/manifestStatusDict";
import ManifestPrintBtn from "../../manifests/components/ManifestPrintBtn";
import ClinicLabel from "../../locations/components/ClinicLabel";
import { CfcIconButton } from "../../../app/components/CfcIconButton";
import { red, green } from "@mui/material/colors";

const InventoryTable = ({
  rows,
  onSelectionUpdate,
  selectedIds,
  isShort,
  defaultSelectedIds,
  statusFilter,
}: {
  rows: ICdtReservation[] | null;
  onSelectionUpdate: (ids: number[]) => void;
  selectedIds?: number[];
  isShort?: boolean;
  defaultSelectedIds?: number[];
  statusFilter?: ManifestStatusEnum;
}) => {
  const { open, toggle } = useDialog();

  const [selectedReservation, setSelectedReservation] =
    useState<ICdtReservation | null>(null);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const { data: manifestStatus } = useAppSelector(
    (state) => state.manifestStatus
  );

  const { data: locations } = useAppSelector((state) => state.location);

  const statusFilerItem = useMemo(() => {
    let filter = { columnField: "manifestStatusId" } as GridFilterItem;
    if (statusFilter) {
      if (statusFilter === ManifestStatusEnum.WithManifest) {
        filter.operatorValue = "isNotEmpty";
        filter.value = "";
      } else if (statusFilter === ManifestStatusEnum.WithoutManifest) {
        filter.operatorValue = "isEmpty";
        filter.value = "";
      } else if (statusFilter === ManifestStatusEnum.Paid) {
      }
    }
    return filter;
  }, [statusFilter]);

  useEffect(() => {
    if (!isShort) {
      setFilterModel({
        items: [statusFilerItem],
      });
    }
  }, [statusFilerItem, isShort]);

  const payInvoice = (event: React.MouseEvent<HTMLElement>) => (id: number) => {
    event.stopPropagation();
    alert(`Paid invoice for reservation ID: ${id}`);
  };

  const viewStocks =
    (event: React.MouseEvent<HTMLElement>) => (item: ICdtReservation) => {
      setSelectedReservation(item);
      toggle(true);
    };

  const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const id = parseInt(event.target.value);
    const options = selectedIds ? [...selectedIds] : [];
    if (checked) {
      options.push(id);
    } else {
      options.splice(options.indexOf(id), 1);
    }
    onSelectionUpdate(options);
  };

  let columns: GridColDef[] = [
    {
      field: "checkbox",
      headerName: "",
      type: "boolean",
      flex: 1,
      maxWidth: 60,
      renderCell: (params) => {
        return (
          <CfcCheckbox
            value={params.row.reservationId}
            checked={selectedIds?.includes(params.row.reservationId)}
            disabled={!!params.row.manifestId || !params.row.paymentReceived}
            onChange={onSelectionChange}
            isDirty={
              defaultSelectedIds &&
              !defaultSelectedIds.includes(params.row.reservationId) &&
              selectedIds?.includes(params.row.reservationId)
            }
          />
        );
      },
    },
    {
      field: "reservationId",
      headerName: "Reservation ID",
      type: "number",
      flex: 1,
    },
    {
      field: "donor",
      headerName: "Donor",
      flex: 1,
      minWidth: 240,
      valueGetter: (params: GridValueGetterParams) => {
        return `${
          params.row.donorCode
        } ${params.row.donorSurName.toUpperCase()}, ${
          params.row.donorFirstName
        }`;
      },
    },
    {
      field: "recipient",
      headerName: "Recipient",
      flex: 1,
      minWidth: 240,
      valueGetter: (params: GridValueGetterParams) => {
        return `${
          params.row.recipientCode
        } ${params.row.recipientSurName.toUpperCase()}, ${
          params.row.recipientFirstName
        }`;
      },
    },
    {
      field: "reservedAmount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      maxWidth: 60,
    },
    {
      field: "paid",
      headerName: "Paid",
      type: "boolean",
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        if (params.row.paymentReceived === true) {
          return <PriceCheckIcon sx={{ color: green[500] }} />;
        } else {
          return (
            <DangerousIcon sx={{ color: red[500] }} />
            // <Button
            //   variant="outlined"
            //   onClick={(event) => payInvoice(event)(params.row.reservationId)}
            // >
            //   Pay Now
            // </Button>
          );
        }
      },
    },
    {
      field: "manifestStatusId",
      headerName: "Manifest Status",
      type: "strintg",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return manifestStatus?.find(
          (status) => status.manifestStatusId === params.row.manifestStatusId
        )?.statusDesc;
      },
      renderCell: (params: GridValueGetterParams) => {
        if (!!params.row.manifestStatusId) {
          return (
            <Chip
              label={
                manifestStatus?.find(
                  (item) =>
                    item.manifestStatusId === params.row.manifestStatusId
                )?.statusDesc
              }
              color={statusDict[params.row.manifestStatusId].color}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "manifestId",
      headerName: "On Manifest",
      type: "number",
      flex: 1,
    },
    {
      field: "reservationDate",
      headerName: "Reservation Date",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return moment(params.row.reservationDate).format("DD/MMM/YYYY");
      },
    },
    {
      field: "reservationClinic",
      headerName: "Current Clinic",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.manifestSendingClinicId)
          return locations?.find(
            (item) => item.locationId === params.row.manifestSendingClinicId
          )?.chartingLabel;
        else {
          return locations?.find(
            (item) => item.locationId === params.row.currentLocationOfSpermId
          )?.chartingLabel;
        }
      },
      renderCell: (params: GridValueGetterParams) => {
        return (
          <ClinicLabel
            locationId={
              params.row.manifestSendingClinicId ??
              params.row.currentLocationOfSpermId
            }
          />
        );
      },
    },
    {
      field: "receivingClinic",
      headerName: "Ship Clinic",
      type: "string",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.manifestReceivingClinicId)
          return locations?.find(
            (item) => item.locationId === params.row.manifestReceivingClinicId
          )?.chartingLabel;
        else {
          return locations?.find(
            (item) => item.locationId === params.row.receivingClinicId
          )?.chartingLabel;
        }
      },
      renderCell: (params: GridValueGetterParams) => {
        return (
          <ClinicLabel
            locationId={
              params.row.manifestReceivingClinicId ??
              params.row.receivingClinicId
            }
          />
        );
      },
    },
    {
      field: "manifestComment",
      headerName: "Comments",
      type: "string",
      flex: 1,
    },
  ];

  if (isShort) {
    columns = columns.filter(
      (col) =>
        !["manifestStatusId", "manifestId", "manifestComment"].includes(
          col.field
        )
    );
    columns.push({
      field: "inventories",
      headerName: "Stocks",
      type: "string",
      flex: 1,
      maxWidth: 80,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <CfcIconButton
            aria-label="stocks"
            color="primary"
            onClick={(event) => viewStocks(event)(params.row)}
          >
            <InventoryIcon />
          </CfcIconButton>
        );
      },
    });
  } else {
    columns.push({
      field: "pdf",
      headerName: "PDF",
      type: "string",
      flex: 1,
      sortable: false,
      description: "Download Manifest PDF",
      maxWidth: 80,
      renderCell: (params) => {
        if (!!params.row.manifestId) {
          return <ManifestPrintBtn manifestId={params.row.manifestId} />;
        } else {
          return null;
        }
      },
    });
  }

  if (rows) {
    return (
      <>
        <DataGrid
          getRowId={(row) => row.reservationId}
          rows={rows}
          columns={columns}
          autoHeight
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [],
            },
          }}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          filterModel={filterModel}
        />
        {selectedReservation && (
          <BootstrapDialog
            open={open}
            toggle={toggle}
            id="view-stocks-dialog"
            title="View Stocks"
            content={<InventoryList reservation={selectedReservation} />}
            fullWidth
            maxWidth="md"
          />
        )}
      </>
    );
  } else return null;
};

export default InventoryTable;
