import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IError from "../../app/interfaces/IError";
import IManifest from "../../app/interfaces/IManifest";
import IManifestItemDict from "../../app/interfaces/IManifestItemDict";
import CfcApi from "../../services/CfcApi";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const initialState: GenericState<IManifestItemDict> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchManifestItem = createAsyncThunk<
  IManifest,
  number,
  { rejectValue: IError }
>("manifestItem/get", async (manifestId: number, { rejectWithValue }) => {
  try {
    const res = await CfcApi.get<IManifest>(
      `/Manifests/GetManifestWithItemsById?manifestId=${manifestId}`
    );
    const { data: manifest } = res;
    if (manifest) {
      return manifest;
    } else {
      return rejectWithValue({
        code: 404,
        message: "Manifests not found",
      });
    }
  } catch (error) {
    return rejectWithValue({
      code: error.response.status,
      message: error.message,
    });
  }
});

export const manifestItemSlice = createSlice({
  name: "manifestItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchManifestItem.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchManifestItem.fulfilled, (state, action) => {
      state.loading = false;

      let data = state.data ? { ...state.data } : {};
      data[action.meta.arg] = action.payload.manifestItems;
      state.data = data;
      state.error = null;
    });
    builder.addCase(fetchManifestItem.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = {
          code: 500,
          message: "An unknown error occurred",
        };
      }
    });
  },
});

export default manifestItemSlice.reducer;
