import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IError from "../../app/interfaces/IError";
import IManifest from "../../app/interfaces/IManifest";
import CfcApi from "../../services/CfcApi";
import {
  genericAsyncThunkReducer,
  GenericState,
} from "../../app/generics/genericAsyncThunkReducer";

const initialState: GenericState<IManifest[]> = {
  loading: false,
  data: null,
  error: null,
};

export const fetchManifestList = createAsyncThunk<
  IManifest[],
  void,
  { rejectValue: IError }
>("manifestList/get", async (val: void, { rejectWithValue }) => {
  try {
    const res = await CfcApi.get<IManifest[]>("/Manifests/GetAllManifests");
    const { data: manifests } = res;
    if (manifests && manifests.length > 0) {
      return manifests;
    } else {
      return rejectWithValue({
        code: 404,
        message: "Manifests not found",
      });
    }
  } catch (error) {
    return rejectWithValue({
      code: error.response.status,
      message: error.message,
    });
  }
});

export const manifestListSlice = createSlice({
  name: "manifestList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    genericAsyncThunkReducer<IManifest[], void>(builder, fetchManifestList);
  },
});

export default manifestListSlice.reducer;
