import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchManifestItem } from "../manifest.item.slice";
import HandleDataWrap from "../../../app/containers/HandleDataWrap";
import { getFormattedDate } from "../functions/getFormattedDate";

interface Props {
  manifestId: number;
}

const ManifestItems = ({ manifestId }: Props) => {
  const dispatch = useAppDispatch();

  const { data, loading, error } = useAppSelector(
    (state) => state.manifestItem
  );

  useEffect(() => {
    dispatch(fetchManifestItem(manifestId));
  }, [manifestId, dispatch]);

  return (
    <Box sx={{ margin: 1, overflow: "auto" }}>
      <HandleDataWrap loading={loading} error={error}>
        <Table size="small" aria-label="manifest-items">
          <TableHead>
            <TableRow>
              <TableCell>Donor Code</TableCell>
              <TableCell>Receipient Name</TableCell>
              <TableCell>Freeze Date</TableCell>
              <TableCell>Tank</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Goblet Color</TableCell>
              <TableCell>Reference</TableCell>
              <TableCell>Inventory Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.[manifestId]?.map((inventory) => (
              <TableRow key={inventory.manifestItemId}>
                <TableCell>{inventory.donorCode}</TableCell>
                <TableCell>
                  {inventory.receipientSurname.toUpperCase()},{" "}
                  {inventory.receipientFirstName}
                </TableCell>
                <TableCell>{getFormattedDate(inventory.freezeDate)}</TableCell>
                <TableCell>{inventory.tankName}</TableCell>
                <TableCell>{inventory.position}</TableCell>
                <TableCell>{inventory.gobletColour}</TableCell>
                <TableCell>{inventory.reference}</TableCell>
                <TableCell>{inventory.inventoryType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HandleDataWrap>
    </Box>
  );
};

export default ManifestItems;
