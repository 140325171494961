import IAuthUser from "../app/interfaces/IAuthUser";

export function getLocalAccessToken() {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}") as IAuthUser;
  return user?.accessToken;
}

export function updateLocalAccessToken(token: string) {
  let user = JSON.parse(localStorage.getItem("user") ?? "{}") as IAuthUser;
  user.accessToken = token;
  localStorage.setItem("user", JSON.stringify(user));
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user") ?? "{}") as IAuthUser;
}

export function setUser(user: IAuthUser) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function removeUser() {
  localStorage.removeItem("user");
}
