import React from "react";
import { styled } from "@mui/material/styles";

interface CfcHeaderProps {
  isLight?: boolean;
}

export const CfcHeader = styled("div")<CfcHeaderProps>(
  ({ isLight, theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: "-2rem",
    marginBottom: "1rem",
    fontSize: isLight ? "1rem" : "1.2rem",
    lineHeight: "1.5rem",
    fontWeight: isLight ? "normal" : "bold",
    background: `linear-gradient(195deg, ${theme.palette.primary.light}, ${theme.palette.primary.main});`,
    color: theme.palette.primary.contrastText,
    borderRadius: "0.5rem",
    boxShadow: `rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, ${theme.palette.primary.light} 0rem 0.4375rem 0.625rem -0.3125rem`,
    opacity: isLight ? 0.8 : 1,
  })
);
