import React from "react";
import { styled } from "@mui/material/styles";
import chroma from "chroma-js";

interface UserLabelSpanProps {
  fontColor: string;
  bgColor: string;
}

export const UserLabelSpan = styled("span")<UserLabelSpanProps>(
  ({ fontColor, bgColor, theme }) => ({
    padding: "4px 10px",
    fontSize: "1rem",
    fontWeight: "bold",
    borderRadius: "8px",
    color: fontColor,
    background: bgColor,
  })
);

export const CfcColorLabel = ({
  color,
  text,
}: {
  color: string;
  text: string;
}) => {
  let bgColor = "white";
  let fontColor = "black";
  const colorDict = color.split("_") ?? []; //standard values: yellow || green_orange || C84F4F
  if (colorDict.length === 2) {
    // use color
    // original color from db are too dark, so we use light color
    if (chroma.valid(colorDict[0]) && chroma.valid(colorDict[1])) {
      bgColor = `linear-gradient(195deg, ${chroma(colorDict[0])
        .brighten()
        .hex()}, ${chroma(colorDict[1]).brighten().hex()})`;
      fontColor =
        chroma.contrast(colorDict[0], "white") > 2 ? "white" : "black";
    }
  } else {
    if (chroma.valid(colorDict[0])) {
      bgColor = chroma(colorDict[0]).brighten().hex();
      fontColor =
        chroma.contrast(colorDict[0], "white") > 2 ? "white" : "black";
    } else if (chroma.valid("#" + colorDict[0])) {
      bgColor = chroma("#" + colorDict[0])
        .brighten()
        .hex();
      fontColor =
        chroma.contrast("#" + colorDict[0], "white") > 2 ? "white" : "black";
    }
  }

  return (
    <UserLabelSpan fontColor={fontColor} bgColor={bgColor}>
      {text}
    </UserLabelSpan>
  );
};
