import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../config/constants";
import * as TokenService from "./token.service";

function getApiUrl(): string {
  return API_URL[process.env.REACT_APP_API_ENDPOINT ?? process.env.NODE_ENV];
}

const CfcApi = axios.create({
  baseURL: getApiUrl(),
  headers: {
    "Content-Type": "application/json",
  },
});

CfcApi.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    let newConfig = { ...config };
    const token = TokenService.getLocalAccessToken();
    if (!newConfig.headers) {
      newConfig.headers = {};
    }
    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

CfcApi.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        TokenService.removeUser();
        window.location.href = "/login";
      }
    }

    return Promise.reject(err);
  }
);

export default CfcApi;
