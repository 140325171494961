import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";

import IManifest from "../../../app/interfaces/IManifest";
import { useAppSelector } from "../../../app/hooks";
import { statusDict } from "../manifestStatusDict";
import { CfcHeader } from "../../../app/components/CfcHeader";
import { getFormattedDate } from "../functions/getFormattedDate";
import UserLabel from "../../users/components/UserLabel";
import ManifestItems from "./ManifestItems";
import ManifestPrintBtn from "./ManifestPrintBtn";
import ClinicLabel from "../../locations/components/ClinicLabel";
import { getFormattedTime } from "../functions/getFormattedTime";

const ManifestDetail = ({ manifest }: { manifest: IManifest }) => {
  const { data: manifestStatus } = useAppSelector(
    (state) => state.manifestStatus
  );

  return (
    <Card>
      <CardHeader
        title={<b>Manifest Id: {manifest.manifestId}</b>}
        subheader={
          <span>
            Created on {getFormattedDate(manifest.manifestCreateDate)} by{" "}
            <UserLabel userId={manifest.createdBy} />
          </span>
        }
      />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: "1rem", textAlign: "left" }}>
              <CfcHeader sx={{ textAlign: "center" }}>
                Manifest Details
              </CfcHeader>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Sending Clinic: </b>{" "}
                    <ClinicLabel locationId={manifest.sendingClinicId} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Receiving Clinic: </b>{" "}
                    <ClinicLabel locationId={manifest.receivingClinicId} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Created Date: </b>{" "}
                    {getFormattedDate(manifest.manifestCreateDate)}{" "}
                    {getFormattedTime(manifest.manifestCreateDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Created By: </b>{" "}
                    <UserLabel userId={manifest.createdBy} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Check Out Date: </b>{" "}
                    {getFormattedDate(manifest.manifestCheckOutDateTime)}{" "}
                    {getFormattedTime(manifest.manifestCheckOutDateTime)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Check Out By: </b>{" "}
                    <UserLabel userId={manifest.manifestCheckOutBy} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Check In Date: </b>{" "}
                    {getFormattedDate(manifest.manifestCheckInDateTime)}{" "}
                    {getFormattedTime(manifest.manifestCheckInDateTime)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Check In By: </b>{" "}
                    <UserLabel userId={manifest.manifestCheckInBy} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Status: </b>{" "}
                    <Chip
                      label={
                        manifestStatus?.find(
                          (item) =>
                            item.manifestStatusId === manifest.manifestStatusId
                        )?.statusDesc
                      }
                      color={statusDict[manifest.manifestStatusId].color}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Comments: </b> {manifest.comments}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: "1rem", textAlign: "center" }}>
              <CfcHeader isLight>Check Out Questions</CfcHeader>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Dry shipper primed: </b> {manifest.manifestCheckOutQues1}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Dry shipper weight: </b> {manifest.manifestCheckOutQues2}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Cryoguard in Dry Shipper: </b>{" "}
                    {manifest.manifestCheckOutQues3}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Cryoguard activated and in date: </b>{" "}
                    {manifest.manifestCheckOutQues4}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Courier Company: </b> {manifest.manifestCheckOutQues5}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Check Out Witness: </b>{" "}
                    <UserLabel userId={manifest.manifestCheckOutWitness} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: "1rem", textAlign: "center" }}>
              <CfcHeader isLight>Check In Questions</CfcHeader>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Dry shipper undamaged: </b>{" "}
                    {manifest.manifestCheckInQues1}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Vapour evident on opening dry shipper: </b>{" "}
                    {manifest.manifestCheckInQues2}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Cryoguard is present and green: </b>{" "}
                    {manifest.manifestCheckInQues3}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Dry shipper weight: </b> {manifest.manifestCheckInQues4}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Check In Witness: </b>{" "}
                    <UserLabel userId={manifest.manifestCheckInWitness} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={6} sx={{ padding: "1rem", textAlign: "center" }}>
              <CfcHeader>Manifest Items</CfcHeader>
              <ManifestItems manifestId={manifest.manifestId} />
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing sx={{ justifyContent: "end" }}>
        <ManifestPrintBtn manifestId={manifest.manifestId} isBig />
      </CardActions>
    </Card>
  );
};

export default ManifestDetail;
